<template>
  <div>
    <div class="tabs-listing" v-if="image == null">
      <v-tabs v-model="active" slider-color="red" show-arrows>
        <v-tab v-for="tab of tabs" :key="tab.title" ripple>
          {{ tab.title + " (" + list_count(tab.title) + ")" }}
        </v-tab>

        <v-tab-item v-for="n in 5" :key="n">
          <v-card v-if="n == 1">
            <div class="pt-2">
              <v-btn
                small
                @click="calculate_stats(admin_tables.soldList, 'sold')"
                >Stats calculation</v-btn
              >
              <div
                v-if="calculation_result.sold != ''"
                v-html="calculation_result.sold"
                class="pa-3"
              ></div>
            </div>
            <div class="table-responsive">
              <v-text-field
                v-model="searchSold"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                clear-icon="mdi-close-circle"
              ></v-text-field>
              <v-data-table
                :headers="headersForSoldList"
                :items="filter(admin_tables.soldList, searchSold)"
                hide-default-footer
                align="center"
                :items-per-page="-1"
                :options="options"
              >
                <template v-slot:item.client_firstname="{ item }">
                  <div v-if="item.gift_name != undefined">
                    <div class="font-weight-black">
                      {{ titleCase(item.gift_name) }}
                    </div>
                    <div class="gray">
                      <router-link
                        :to="'/admin-panel/customer-account/' + item.client_id"
                        class="black--text"
                      >
                        (from
                        {{
                          titleCase(
                            item.client_firstname + " " + item.client_lastname
                          )
                        }})
                      </router-link>
                    </div>
                    <p>
                      <v-img width="35" src="/static/images/gift.png" />
                    </p>
                  </div>
                  <div v-else-if="item.group_name != undefined">
                    <div class="font-weight-black">
                      {{ titleCase(item.group_name) }}
                    </div>
                    <div class="gray">
                      <router-link
                        :to="'/admin-panel/customer-account/' + item.client_id"
                        class="black--text"
                      >
                        (from
                        {{
                          titleCase(
                            item.client_firstname + " " + item.client_lastname
                          )
                        }})
                      </router-link>
                    </div>
                    <p>
                      <v-img width="35" src="/static/images/group.png" />
                    </p>
                  </div>
                  <div class="font-weight-black" v-else>
                    {{
                      titleCase(
                        item.client_firstname + " " + item.client_lastname
                      )
                    }}
                  </div>
                </template>
                <template v-slot:item.email="{ item }">
                  <!-- <div v-if="item.client_interac_email != undefined">
                    I: {{ item.client_interac_email }}
                  </div> -->
                  <div @click="copyToClipboard(item.client_email)">
                    {{ item.client_email }}
                  </div>
                  <div v-if="item.gift_email != undefined">
                    Gift: {{ item.gift_email }}
                  </div>
                </template>
                <template v-slot:item.order="{ item }">
                  {{ formatDate(item.order) }}
                </template>
                <template v-slot:item.total_amount="{ item }">
                  <!-- {{ $formatMoney(item.total_amount, 2, "USD") }} -->
                  <div
                    :class="
                      ' accent-1 ' +
                      (item.total_amount_currency.amount == 0
                        ? 'pink lighten-4 white--text pa-2'
                        : 'yellow pa-2')
                    "
                  >
                    Paid:
                    {{
                      $formatMoney(
                        item.total_amount_currency.amount,
                        2,
                        item.total_amount_currency.currency,
                        1
                      )
                    }}
                  </div>
                  <div>
                    Amount: {{ $formatMoney(item.total_amount, 2, "USD") }}
                  </div>
                  <div>Credit: {{ $formatMoney(item.credit, 2, "USD") }}</div>
                </template>
                <template v-slot:item.credit="{ item }">
                  <div v-if="item.order_type == 'INTERAC'">
                    {{ $formatMoney(item.credit, 2, "USD") }}
                  </div>
                </template>
                <template v-slot:item.coupon="{ item }">
                  <div v-if="item.coupon != undefined && item.coupon_rebate > 0">
                    {{
                      item.coupon +
                      " (" +
                      item.coupon_rebate +
                      "%) " +
                      $formatMoney(item.coupon_amount, 2, "USD")
                    }}
                  </div>
                  <div v-else-if="item.coupon != undefined">
                    {{
                      item.coupon +
                      $formatMoney(item.coupon_amount, 2, "USD")
                    }}
                  </div>                  
                  <div v-else>-</div>
                </template>
                <template v-slot:item.total_amount_currency.amount="{ item }">
                  <div
                    :class="
                      ' accent-1 ' +
                      (item.total_amount_currency.amount == 0
                        ? 'pink lighten-4 white--text pa-2'
                        : 'yellow pa-2')
                    "
                  >
                    {{
                      $formatMoney(
                        item.total_amount_currency.amount,
                        2,
                        item.total_amount_currency.currency,
                        1
                      )
                    }}
                  </div>
                </template>
                <template v-slot:item.assign_to="{ item }">
                  <v-btn fab x-small @click="assignOrder(item, 'soldList')">
                    <v-icon color="green darken-2">mdi-check</v-icon>
                  </v-btn>
                  <!-- <v-select
                    :items="userInfoAssignTask"
                    item-text="display_name"
                    item-value="uid"
                    v-model="item.assigned_to"
                    label="Assign to..."
                  >
                  </v-select> -->
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="white--text">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab x-small v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title @click="cancelOrder(item)">
                            <v-icon color="red darken-2">mdi-trash-can</v-icon>
                            Delete order
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          :disabled="
                            sendindMail ||
                            emailSent[item.id] != undefined ||
                            item.email_payment_reminder
                          "
                        >
                          <v-list-item-title @click="sendReminder(item)">
                            <v-icon>mdi-email</v-icon>
                            Send payment reminder
                            <v-icon
                              color="green"
                              v-if="item.email_payment_reminder"
                              >mdi-check-bold</v-icon
                            >
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <!-- <v-btn fab x-small @click="moreActions(item)">
                      <v-icon color="red darken-2">mdi-dots-vertical</v-icon>
                    </v-btn> -->
                    <!-- - - - - - - - - - - - - - - - - - - - - -->
                    <!-- <v-btn
                      fab
                      x-small
                      :disabled="item.assigned_to == undefined"
                      @click="assignOrder(item, 'soldList')"
                    >
                      <v-icon color="green darken-2">mdi-check</v-icon>
                    </v-btn> -->
                  </div>
                </template>

                <template v-slot:item.order_desc="{ item }">
                  <div v-for="(product, index) in item.products" :key="index">
                    <v-row :class="getRowColor(index)">
                      <v-col>
                        <v-img
                          width="50"
                          :src="img_mapping[product.ticket.type_name]"
                        />
                      </v-col>
                      <v-col
                        v-if="
                          product.ticket.quickPicks != undefined &&
                          product.ticket.quickPicks != 0
                        "
                      >
                        <div v-if="product.ticket.group != undefined">
                          <b> Group: {{ product.ticket.group }}</b>
                        </div>
                        {{ product.ticket.quickPicks + " quickpicks" }}
                      </v-col>
                      <v-col v-else>
                        <div>
                          {{ product.ticket.grids.length + " grids" }}
                        </div>
                        <div
                          v-for="(line, index) in getDetails(product)"
                          :key="index"
                        >
                          {{ line }}
                        </div>
                      </v-col>
                      <v-col v-if="product.ticket.multiplier"
                        ><mark>{{
                          "with " +
                          $t(
                            "message.productsPage.multiplier." +
                              product.ticket.type_name +
                              ".name"
                          )
                        }}</mark>
                      </v-col>
                      <v-col v-if="product.ticket.multiplay > 1">
                        <div>
                          {{ "Number of draw: " }}
                          <b>{{ product.ticket.multiplay.toString() }}</b>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </template>
                <template v-slot:item.order_date="{ item }">
                  {{ item.order_date.toDate().toLocaleDateString("fr") }}
                </template>
              </v-data-table>
            </div>
            <div
              class="d-flex justify-center mt-16 pt-6"
              v-if="admin_tables.soldList.length > 0"
            >
              <v-btn color="red" class="white--text" @click="cancellAllSold()"
                >Cancel All Sold
                <v-icon right>mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
          </v-card>

          <v-card flat v-if="n == 2">
            <div class="pt-2 d-flex justify-start">
              <div class="mx-2">
                <v-btn
                  small
                  @click="
                    calculate_stats(admin_tables.assignedList, 'assigned')
                  "
                  >Stats calculation</v-btn
                >
              </div>
              <div>
                <v-btn small @click="scan_batch()">Scan batch [beta]</v-btn>
              </div>
              <div>
                <v-btn small @click="scan_with_scanner()">Scan with scanner [beta]</v-btn>
              </div>
            </div>
            <div>
              <div
                v-if="calculation_result.assigned != ''"
                v-html="calculation_result.assigned"
                class="pa-3"
              ></div>
            </div>
            <div class="table-responsive">
              <v-text-field
                v-model="searchAssigned"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                clear-icon="mdi-close-circle"
              ></v-text-field>
              <v-data-table
                :headers="headersForAssignedList"
                :items="filter(admin_tables.assignedList, searchAssigned)"
                hide-default-footer
                align="center"
                :items-per-page="-1"
                :options="options"
              >
                <template v-slot:item.client_firstname="{ item }">
                  <div v-if="item.gift_name != undefined">
                    <div class="font-weight-black">
                      {{ titleCase(item.gift_name) }}
                    </div>
                    <div class="gray">
                      <router-link
                        :to="'/admin-panel/customer-account/' + item.client_id"
                        class="black--text"
                      >
                        (from
                        {{
                          titleCase(
                            item.client_firstname + " " + item.client_lastname
                          )
                        }})
                      </router-link>
                    </div>
                    <p>
                      <v-img width="35" src="/static/images/gift.png" />
                    </p>
                  </div>

                  <div v-else-if="item.group_name != undefined">
                    <div class="font-weight-black">
                      {{ titleCase(item.group_name) }}
                    </div>
                    <div class="gray">
                      <router-link
                        :to="'/admin-panel/customer-account/' + item.client_id"
                        class="black--text"
                      >
                        (from
                        {{
                          titleCase(
                            item.client_firstname + " " + item.client_lastname
                          )
                        }})
                      </router-link>
                    </div>
                    <p>
                      <v-img width="35" src="/static/images/group.png" />
                    </p>
                  </div>

                  <div class="font-weight-black" v-else>
                    {{
                      titleCase(
                        item.client_firstname + " " + item.client_lastname
                      )
                    }}
                  </div>
                </template>
                <template v-slot:item.email="{ item }">
                  <div @click="copyToClipboard(item.client_email)">
                    {{ item.client_email }}
                  </div>
                  <div v-if="item.gift_email != undefined">
                    Gift: {{ item.gift_email }}
                  </div>
                </template>
                <template v-slot:item.order_date="{ item }">
                  {{ formatDate(item.order_date) }}
                </template>
                <template v-slot:item.order_desc="{ item }">
                  <div v-for="(product, index) in item.products" :key="index">
                    <v-row :class="getRowColor(index)">
                      <v-col>
                        <v-img
                          width="50"
                          :src="img_mapping[product.ticket.type_name]"
                        />
                      </v-col>
                      <v-col
                        v-if="
                          product.ticket.quickPicks != undefined &&
                          product.ticket.quickPicks != 0
                        "
                        >{{ product.ticket.quickPicks + " quickpicks" }}
                      </v-col>
                      <v-col v-else>
                        <div>
                          {{ product.ticket.grids.length + " grids" }}
                        </div>
                        <div
                          v-for="(line, index) in getDetails(product)"
                          :key="index"
                        >
                          {{ line }}
                        </div>
                      </v-col>
                      <v-col v-if="product.ticket.multiplier"
                        ><mark>{{
                          "with " +
                          $t(
                            "message.productsPage.multiplier." +
                              product.ticket.type_name +
                              ".name"
                          )
                        }}</mark>
                      </v-col>
                      <v-col v-if="product.ticket.multiplay > 1">
                        <div>
                          {{ "Number of draw: " }}
                          <b>{{ product.ticket.multiplay.toString() }}</b>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </template>
                <template v-slot:item.total_amount="{ item }">
                  <div
                    :class="
                      ' accent-1 ' +
                      (item.total_amount_currency.amount == 0
                        ? 'pink lighten-4 white--text pa-2'
                        : 'yellow pa-2')
                    "
                  >
                    Paid:
                    {{
                      $formatMoney(
                        item.total_amount_currency.amount,
                        2,
                        item.total_amount_currency.currency,
                        1
                      )
                    }}
                  </div>
                  <div>
                    Amount: {{ $formatMoney(item.total_amount, 2, "USD") }}
                  </div>
                  <div>Credit: {{ $formatMoney(item.credit, 2, "USD") }}</div>
                </template>
                <!-- <template v-slot:item.total_amount_currency.amount="{ item }">
                  {{
                    $formatMoney(
                      item.total_amount_currency.amount,
                      2,
                      item.total_amount_currency.currency,
                      1
                    )
                  }}
                </template>
                <template v-slot:item.credit="{ item }">
                  <div v-if="item.order_type == 'INTERAC'">
                    {{ $formatMoney(item.credit, 2, "USD") }}
                  </div>
                  <div v-if="item.order_type == 'STRIPE'">
                    {{
                      $formatMoney(item.total_amount_currency.credit, 2, "USD")
                    }}
                  </div>
                </template> -->
                <template v-slot:item.coupon="{ item }">
                  <div v-if="item.coupon != undefined">
                    {{
                      item.coupon +
                      " (" +
                      item.coupon_rebate +
                      "%) " +
                      $formatMoney(item.coupon_amount, 2, "USD")
                    }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:item.assigned_to="{ item }">
                  {{ getDisplayNameFromUid(item.assigned_to) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="flex">
                    <!-- <router-link :to="'/admin-panel/order/' + item.id"> -->
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      x-small
                      color="primary"
                      @click="editOrder(item)"
                    >
                      <v-icon dark> mdi-pencil</v-icon>
                    </v-btn>
                    <!-- </router-link> -->
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-card>
          <v-card flat v-if="n == 3">
            <div class="pt-2">
              <v-btn
                small
                @click="
                  calculate_stats(admin_tables.completedList, 'completed')
                "
                >Stats calculation</v-btn
              >
              <div
                v-if="calculation_result.completed != ''"
                v-html="calculation_result.completed"
                class="pa-3"
              ></div>
            </div>
            <div class="table-responsive">
              <v-text-field
                v-model="searchCompleted"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                clear-icon="mdi-close-circle"
              ></v-text-field>
              <v-data-table
                :headers="headersForCompletedList"
                :items="filter(admin_tables.completedList, searchCompleted)"
                hide-default-footer
                align="center"
                :items-per-page="-1"
                :options="options"
              >
                <template v-slot:item.client_firstname="{ item }">
                  <div v-if="item.gift_name != undefined">
                    <div class="font-weight-black">{{ item.gift_name }}</div>
                    <div class="gray">
                      <router-link
                        :to="'/admin-panel/customer-account/' + item.client_id"
                        class="black--text"
                      >
                        (from
                        {{
                          item.client_firstname + " " + item.client_lastname
                        }})
                      </router-link>
                    </div>
                    <p>
                      <v-img width="35" src="/static/images/gift.png" />
                    </p>
                  </div>
                  <div v-else-if="item.group_name != undefined">
                    <div class="font-weight-black">{{ item.group_name }}</div>
                    <div class="gray">
                      <router-link
                        :to="'/admin-panel/customer-account/' + item.client_id"
                        class="black--text"
                      >
                        (from
                        {{
                          item.client_firstname + " " + item.client_lastname
                        }})
                      </router-link>
                    </div>
                    <p>
                      <v-img width="35" src="/static/images/group.png" />
                    </p>
                  </div>
                  <div class="font-weight-black" v-else>
                    {{ item.client_firstname + " " + item.client_lastname }}
                  </div>
                </template>
                <template v-slot:item.email="{ item }">
                  <div @click="copyToClipboard(item.client_email)">
                    {{ item.client_email }}
                  </div>
                  <div v-if="item.gift_email != undefined">
                    Gift: {{ item.gift_email }}
                  </div>
                </template>
                <template v-slot:item.order_date="{ item }">
                  {{ formatDate(item.order_date) }}
                </template>
                <template v-slot:item.order_desc="{ item }">
                  <div v-for="(product, index) in item.products" :key="index">
                    <v-row :class="getRowColor(index)">
                      <v-col>
                        <v-img
                          width="50"
                          :src="img_mapping[product.ticket.type_name]"
                        />
                      </v-col>
                      <v-col
                        v-if="
                          product.ticket.quickPicks != undefined &&
                          product.ticket.quickPicks != 0
                        "
                        >{{ product.ticket.quickPicks + " quickpicks" }}
                      </v-col>
                      <v-col v-else>
                        <div>
                          {{ product.ticket.grids.length + " grids" }}
                        </div>
                        <div
                          v-for="(line, index) in getDetails(product)"
                          :key="index"
                        >
                          {{ line }}
                        </div>
                      </v-col>
                      <v-col v-if="product.ticket.multiplier"
                        ><mark>{{
                          "with " +
                          $t(
                            "message.productsPage.multiplier." +
                              product.ticket.type_name +
                              ".name"
                          )
                        }}</mark>
                      </v-col>
                      <v-col v-if="product.ticket.multiplay > 1">
                        <div>
                          {{ "Number of draw: " }}
                          <b>{{ product.ticket.multiplay.toString() }}</b>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </template>
                <template v-slot:item.total_amount="{ item }">
                  <div class="yellow accent-1">
                    {{ $formatMoney(item.total_amount, 2, "USD") }}
                  </div>
                </template>
                <template v-slot:item.total_amount_currency.amount="{ item }">
                  {{
                    $formatMoney(
                      item.total_amount_currency.amount,
                      2,
                      item.total_amount_currency.currency,
                      1
                    )
                  }}
                </template>
                <template v-slot:item.credit="{ item }">
                  <div v-if="item.order_type == 'INTERAC'">
                    {{ $formatMoney(item.credit, 2, "USD") }}
                  </div>
                  <div v-if="item.order_type == 'STRIPE'">
                    {{
                      $formatMoney(item.total_amount_currency.credit, 2, "USD")
                    }}
                  </div>
                </template>
                <template v-slot:item.coupon="{ item }">
                  <div v-if="item.coupon != undefined">
                    {{
                      item.coupon +
                      " (" +
                      item.coupon_rebate +
                      "%) " +
                      $formatMoney(item.coupon_amount, 2, "USD")
                    }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:item.assigned_to="{ item }">
                  {{ getDisplayNameFromUid(item.assigned_to) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="flex">
                    <!-- <router-link :to="'/admin-panel/order/' + item.id"> -->
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      x-small
                      color="primary"
                      @click="editOrder(item)"
                    >
                      <v-icon dark> mdi-pencil</v-icon>
                    </v-btn>
                    <!-- </router-link> -->
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-card>
          <v-card flat v-if="n == 4">
            <div class="table-responsive">
              <v-text-field
                v-model="searchCanceled"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                clear-icon="mdi-close-circle"
              ></v-text-field>
              <v-data-table
                :headers="headersForCanceledList"
                :items="filter(admin_tables.canceledList, searchCanceled)"
                hide-default-footer
                align="center"
                :items-per-page="-1"
                :options="options"
              >
                <template v-slot:item.client_firstname="{ item }">
                  <div v-if="item.gift_name != undefined">
                    <div class="font-weight-black">{{ item.gift_name }}</div>
                    <div class="gray">
                      <router-link
                        :to="'/admin-panel/customer-account/' + item.client_id"
                        class="black--text"
                      >
                        (from
                        {{
                          item.client_firstname + " " + item.client_lastname
                        }})
                      </router-link>
                    </div>
                    <p>
                      <v-img width="35" src="/static/images/gift.png" />
                    </p>
                  </div>

                  <div v-else-if="item.group_name != undefined">
                    <div class="font-weight-black">{{ item.group_name }}</div>
                    <div class="gray">
                      <router-link
                        :to="'/admin-panel/customer-account/' + item.client_id"
                        class="black--text"
                      >
                        (from
                        {{
                          item.client_firstname + " " + item.client_lastname
                        }})
                      </router-link>
                    </div>
                    <p>
                      <v-img width="35" src="/static/images/group.png" />
                    </p>
                  </div>

                  <div class="font-weight-black" v-else>
                    {{ item.client_firstname + " " + item.client_lastname }}
                  </div>
                </template>
                <template v-slot:item.email="{ item }">
                  <div @click="copyToClipboard(item.client_email)">
                    {{ item.client_email }}
                  </div>
                  <div v-if="item.gift_email != undefined">
                    Gift: {{ item.gift_email }}
                  </div>
                </template>
                <template v-slot:item.order_date="{ item }">
                  {{ formatDate(item.order_date) }}
                </template>
                <template v-slot:item.order_desc="{ item }">
                  <div v-for="(product, index) in item.products" :key="index">
                    <v-row :class="getRowColor(index)">
                      <v-col>
                        <v-img
                          width="50"
                          :src="img_mapping[product.ticket.type_name]"
                        />
                      </v-col>
                      <v-col
                        v-if="
                          product.ticket.quickPicks != undefined &&
                          product.ticket.quickPicks != 0
                        "
                        >{{ product.ticket.quickPicks + " quickpicks" }}
                      </v-col>
                      <v-col v-else>
                        <div>
                          {{ product.ticket.grids.length + " grids" }}
                        </div>
                        <div
                          v-for="(line, index) in getDetails(product)"
                          :key="index"
                        >
                          {{ line }}
                        </div>
                      </v-col>
                      <v-col v-if="product.ticket.multiplier"
                        ><mark>{{
                          "with " +
                          $t(
                            "message.productsPage.multiplier." +
                              product.ticket.type_name +
                              ".name"
                          )
                        }}</mark>
                      </v-col>
                      <v-col v-if="product.ticket.multiplay > 1">
                        <div>
                          {{ "Number of draw: " }}
                          <b>{{ product.ticket.multiplay.toString() }}</b>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </template>
                <template v-slot:item.total_amount="{ item }">
                  <div class="yellow accent-1">
                    {{ $formatMoney(item.total_amount, 2, "USD") }}
                  </div>
                </template>
                <template v-slot:item.total_amount_currency.amount="{ item }">
                  {{
                    $formatMoney(
                      item.total_amount_currency.amount,
                      2,
                      item.total_amount_currency.currency,
                      1
                    )
                  }}
                </template>
                <template v-slot:item.credit="{ item }">
                  <div v-if="item.order_type == 'INTERAC'">
                    {{ $formatMoney(item.credit, 2, "USD") }}
                  </div>
                  <div v-if="item.order_type == 'STRIPE'">
                    {{
                      $formatMoney(item.total_amount_currency.credit, 2, "USD")
                    }}
                  </div>
                </template>
                <template v-slot:item.coupon="{ item }">
                  <div v-if="item.coupon != undefined">
                    {{
                      item.coupon +
                      " (" +
                      item.coupon_rebate +
                      "%) " +
                      $formatMoney(item.coupon_amount, 2, "USD")
                    }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:item.assigned_to="{ item }">
                  {{ getDisplayNameFromUid(item.assigned_to) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="flex">
                    <!-- <router-link :to="'/admin-panel/order/' + item.id"> -->
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      x-small
                      color="primary"
                      @click="editOrder(item)"
                    >
                      <v-icon dark> mdi-pencil</v-icon>
                    </v-btn>
                    <!-- </router-link> -->
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-card>
          <v-card flat v-if="n == 5">
            <div class="table-responsive">
              <v-text-field
                v-model="searchWinning"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                clear-icon="mdi-close-circle"
              ></v-text-field>
              <v-data-table
                :headers="headersForWinningList"
                :items="filter(admin_tables.winningList, searchWinning)"
                align="center"
                hide-default-footer
                :items-per-page="-1"
                :options="options"
              >
                <template v-slot:item.client_firstname="{ item }">
                  <div v-if="item.gift_name != undefined">
                    <div class="font-weight-black">{{ item.gift_name }}</div>
                    <div class="gray">
                      <router-link
                        :to="'/admin-panel/customer-account/' + item.client_id"
                        class="black--text"
                      >
                        (from
                        {{
                          item.client_firstname + " " + item.client_lastname
                        }})
                      </router-link>
                    </div>
                    <p>
                      <v-img width="35" src="/static/images/gift.png" />
                    </p>
                  </div>

                  <div v-if="item.group_name != undefined">
                    <div class="font-weight-black">{{ item.group_name }}</div>
                    <div class="gray">
                      <router-link
                        :to="'/admin-panel/customer-account/' + item.client_id"
                        class="black--text"
                      >
                        (from
                        {{
                          item.client_firstname + " " + item.client_lastname
                        }})
                      </router-link>
                    </div>
                    <p>
                      <v-img width="35" src="/static/images/group.png" />
                    </p>
                  </div>

                  <div class="font-weight-black" v-else>
                    {{ item.client_firstname + " " + item.client_lastname }}
                  </div>
                </template>
                <template v-slot:item.amount="{ item }">
                  {{ $formatMoney(getWinningAmount(item), 2, "USD") }}
                </template>
                <template v-slot:item.numbers="{ item }">
                  <div
                    v-for="(numbers, index) in getWinningNumbers(item)"
                    :key="index"
                  >
                    {{ numbers }}
                  </div>
                </template>

                <template v-slot:item.image="{ item }">
                  <v-btn fab x-small @click="image = item.image_url">
                    <v-icon>mdi-image</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.credit="{ item }">
                  <v-btn @click="creditDialog(item)"> Credit </v-btn>
                </template>
                <template v-slot:item.interac="{ item }">
                  <v-btn @click="interacDialog(item)"> Interac </v-btn>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs>

      <emb-delete-confirmation
        ref="cancelOrderConfirmationDialog"
        message="Are you sure you want to cancel this order?"
        @onConfirm="onCancelOrder"
      >
      </emb-delete-confirmation>

      <emb-delete-confirmation
        ref="cancelSoldOrdersConfirmationDialog"
        message="Are you sure you want to cancel ALL sold orders?"
        @onConfirm="onCancelAllSoldOrders"
      >
      </emb-delete-confirmation>

      <emb-delete-confirmation
        ref="creditCustomerDialog"
        :message="`Are you sure you want add ${total_amount_dialog} to the customer credit?`"
        @onConfirm="onCreditConfirmation"
      >
      </emb-delete-confirmation>

      <emb-delete-confirmation
        ref="transfertInteracDialog"
        :message="`Did you tranfer the amount of ${total_amount_dialog}?`"
        @onConfirm="onInteracDone"
      >
      </emb-delete-confirmation>
    </div>
    <div v-else>
      <v-btn elevation="2" icon @click="closeImage()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-img :src="image" />
    </div>
  </div>
</template>
<style scoped>
.flex {
  display: flex;
}
</style>
<script>
// import api from "Api";
import { mapGetters } from "vuex";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  getFirestore,
  getDocs,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  computed: {
    ...mapGetters([
      "getUserInfoAssignTask",
      "getDisplayNameFromUid",
      "getLastTab",
      "admin_tables",
    ]),
  },
  watch: {
    active: function () {
      this.$store.commit("setLastTab", {
        page: "orders",
        tabIndex: this.active,
      });
    },
    "$store.state.isAdmin": function () {
      this.fetch_assign_users();
      this.fetch_report();
    },
  },
  data() {
    return {
      img_mapping: {
        megamillions: "/static/images/megamillions_mini.png",
        powerball: "/static/images/powerball_mini.png",
      },
      image: null,
      orderToCancel: null,
      total_amount_dialog: 0,
      ticket_to_update: null,
      sendindMail: false, // Sending reminder payment mail
      emailSent: {},
      searchSold: "",
      searchAssigned: "",
      searchCompleted: "",
      searchCanceled: "",
      searchWinning: "",
      calculation_result: { sold: "", assigned: "", completed: "" },
      options: {
        sortBy: ["client_firstname"],
        multiSort: false,
      },
      headersForSoldList: [
        {
          text: "Name",
          sortable: true,
          value: "client_firstname",
        },
        {
          text: "Email",
          sortable: false,
          value: "email",
        },
        {
          text: "Order",
          sortable: false,
          value: "order_desc",
        },
        // {
        //   text: "Order Id",
        //   sortable: false,
        //   value: "id",
        // },
        {
          text: "Transaction Date",
          sortable: true,
          value: "order_date",
        },
        {
          text: "Amount",
          sortable: true,
          value: "total_amount",
        },
        // {
        //   text: "Paid Amount",
        //   sortable: true,
        //   value: "total_amount_currency.amount",
        // },
        // {
        //   text: "Credit",
        //   sortable: true,
        //   value: "credit",
        // },
        // {
        //   text: "Coupon",
        //   sortable: false,
        //   value: "coupon",
        // },
        {
          text: "Assign to",
          sortable: false,
          value: "assign_to",
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
        },
      ],
      headersForAssignedList: [
        {
          text: "Name",
          sortable: true,
          value: "client_firstname",
        },
        {
          text: "email",
          sortable: false,
          value: "email",
        },
        {
          text: "Order",
          sortable: false,
          value: "order_desc",
        },
        // {
        //   text: "Order Id",
        //   sortable: false,
        //   value: "id",
        // },
        {
          text: "Transaction Date",
          sortable: true,
          value: "order_date",
        },
        // {
        //   text: "Assigned to",
        //   sortable: false,
        //   value: "assigned_to",
        // },
        {
          text: "Amount",
          sortable: true,
          value: "total_amount",
        },
        // {
        //   text: "Paid Amount",
        //   sortable: true,
        //   value: "total_amount_currency.amount",
        // },
        // {
        //   text: "Credit",
        //   sortable: true,
        //   value: "credit",
        // },
        // {
        //   text: "Coupon",
        //   sortable: false,
        //   value: "coupon",
        // },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
        },
      ],
      headersForCompletedList: [
        {
          text: "Name",
          sortable: true,
          value: "client_firstname",
        },
        {
          text: "Email",
          sortable: false,
          value: "email",
        },
        {
          text: "Order",
          sortable: false,
          value: "order_desc",
        },
        {
          text: "Order Id",
          sortable: false,
          value: "id",
        },
        {
          text: "Transaction Date",
          sortable: true,
          value: "order_date",
        },
        // {
        //   text: "Assigned to",
        //   sortable: false,
        //   value: "assigned_to",
        // },
        {
          text: "Amount",
          sortable: true,
          value: "total_amount",
        },
        {
          text: "Paid Amount",
          sortable: true,
          value: "total_amount_currency.amount",
        },
        {
          text: "Credit",
          sortable: true,
          value: "credit",
        },
        {
          text: "Coupon",
          sortable: false,
          value: "coupon",
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
        },
      ],
      headersForCanceledList: [
        {
          text: "Name",
          sortable: true,
          value: "client_firstname",
        },
        {
          text: "Email",
          sortable: false,
          value: "email",
        },
        {
          text: "Order",
          sortable: false,
          value: "order_desc",
        },
        {
          text: "Order Id",
          sortable: false,
          value: "id",
        },
        {
          text: "Transaction Date",
          sortable: true,
          value: "order_date",
        },
        {
          text: "Assigned to",
          sortable: false,
          value: "assigned_to",
        },
        {
          text: "Amount",
          sortable: true,
          value: "total_amount",
        },
        {
          text: "Paid Amount",
          sortable: true,
          value: "total_amount_currency.amount",
        },
        {
          text: "Credit",
          sortable: true,
          value: "credit",
        },
        {
          text: "Coupon",
          sortable: false,
          value: "coupon",
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
        },
      ],
      headersForWinningList: [
        {
          text: "Type",
          sortable: false,
          value: "type_name",
        },
        {
          text: "Name",
          sortable: true,
          value: "client_firstname",
        },
        {
          text: "Amount",
          sortable: true,
          value: "amount",
        },
        {
          text: "Numbers",
          sortable: false,
          value: "numbers",
        },
        {
          text: "Ticket Id",
          sortable: false,
          value: "id",
        },
        {
          text: "See Ticket",
          sortable: false,
          value: "image",
        },
        {
          text: "Credit to user account",
          sortable: false,
          value: "credit",
        },
        {
          text: "Transfert to Interac",
          sortable: false,
          value: "interac",
        },
      ],
      // tabsAndTableDetails: {
      //   soldList: [],
      //   assignedList: [],
      //   winningList: [],
      // },
      active: null,
      tabs: [
        {
          title: "Sold",
          list: "soldList",
        },
        {
          title: "Assigned",
          list: "assignedList",
        },
        {
          title: "Completed",
          list: "completedList",
        },
        {
          title: "Canceled",
          list: "canceledList",
        },
        {
          title: "Winning",
          list: "winningList",
        },
      ],
      userInfoAssignTask: [],
      multiplier_label: {
        megamillions: {
          type: "Megamillions",
          multiplier: "<b> with Megaplier </b>",
          no_multiplier: "",
        },
        powerball: {
          type: "Powerball",
          multiplier: "<b> with Powerplay </b>",
          no_multiplier: "",
        },
      },
    };
  },
  mounted() {
    this.active = this.getLastTab("orders");
    this.fetch_assign_users();
    this.fetch_report();
  },
  methods: {
    next() {
      const active = parseInt(this.active);
      this.active = active < 2 ? active + 1 : 0;
    },
    fetch_assign_users() {
      if (this.getUserInfoAssignTask == null) {
        const q = query(
          collection(getFirestore(), "users_private"),
          where("assign_task", "==", true)
        );

        getDocs(q)
          .then((querySnapshot) => {
            const temp_user_list = [];
            querySnapshot.forEach((doc) => {
              temp_user_list.push(doc.data());
            });
            this.$store.commit("setUserInfoAssignTask", temp_user_list);
            this.userInfoAssignTask = temp_user_list;
          })
          .catch((error) => {
            console.error("Error retrieving users: ", error);
          });
      } else {
        this.userInfoAssignTask = this.getUserInfoAssignTask;
      }
    },
    fetch_report() {
      var today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      var tomorrow = new Date();
      tomorrow.setHours(0);
      tomorrow.setMinutes(0);
      tomorrow.setSeconds(0);
      tomorrow.setMilliseconds(0);
      tomorrow.setDate(tomorrow.getDate() + 1);
      if (this.admin_tables.soldlistener == null) {
        this.admin_tables.soldlistener = onSnapshot(
          query(
            collection(getFirestore(), "orders"),
            where("status", "==", "SOLD"),
            orderBy("order_date", "asc")
          ),
          (querySnapshot) => {
            const soldDocs = querySnapshot.docs.map((doc) => doc.data());
            this.admin_tables["soldList"] = soldDocs;
          }
        );
      }
      if (this.admin_tables.assigned_listener == null) {
        this.admin_tables.assigned_listener = onSnapshot(
          query(
            collection(getFirestore(), "orders"),
            where("status", "==", "ASSIGNED"),
            orderBy("order_date", "asc")
          ),
          (querySnapshot) => {
            const assignedDocs = querySnapshot.docs.map((doc) => doc.data());
            this.admin_tables["assignedList"] = assignedDocs;
          }
        );
      }
      if (this.admin_tables.completed_listener == null) {
        this.admin_tables.completed_listener = onSnapshot(
          query(
            collection(getFirestore(), "orders"),
            where("status", "==", "COMPLETED"),
            where("update_date", ">=", today),
            where("update_date", "<", tomorrow),
            orderBy("update_date", "asc")
          ),
          (querySnapshot) => {
            const completedDocs = querySnapshot.docs.map((doc) => doc.data());
            this.admin_tables["completedList"] = completedDocs;
          }
        );
      }
      if (this.admin_tables.canceled_listener == null) {
        this.admin_tables.canceled_listener = onSnapshot(
          query(
            collection(getFirestore(), "orders"),
            where("status", "==", "CANCELED"),
            where("update_date", ">=", today),
            where("update_date", "<", tomorrow),
            orderBy("update_date", "asc")
          ),
          (querySnapshot) => {
            const canceledDocs = querySnapshot.docs.map((doc) => doc.data());
            this.admin_tables["canceledList"] = canceledDocs;
          }
        );
      }
      if (this.admin_tables.winning_listener == null) {
        this.admin_tables.winning_listener = onSnapshot(
          query(
            collection(getFirestore(), "users_tickets"),
            where("status", "==", "WINNING"),
            orderBy("first_draw_date", "asc")
          ),
          (querySnapshot) => {
            const winningDocs = querySnapshot.docs.map((doc) => doc.data());
            this.admin_tables["winningList"] = winningDocs;
          }
        );
      }
    },
    formatDate(timestamp) {
      var d = timestamp.toDate();
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      return `${da}-${mo}-${ye}`;
    },
    assignOrder(order) {
      order.assigned_to = this.getUserInfoAssignTask[0].uid;
      order.status = "ASSIGNED";
      this.$store.dispatch("assign_order", order).then(() => {
        var info = {
          amount: order.total_amount,
          payment_type: "INTERAC",
          to: order.assigned_to,
        };
        info["amount" + order.total_amount_currency.currency] =
          order.total_amount_currency.amount;
        this.$gtag.event("assign_order", info);
      });
    },
    // type: quickPicks or grids
    orderStat(order, type) {
      var quickPicksTotal = 0;
      var gridTotal = 0;
      for (var product of order.products) {
        if ("quickPicks" in product.ticket) {
          quickPicksTotal += product.ticket.quickPicks;
        } else if ("grids" in product.ticket) {
          gridTotal += product.ticket.grids.length;
        }
      }

      if (type == "quickPicks") {
        return quickPicksTotal;
      } else {
        return gridTotal;
      }
    },
    editOrder(order) {
      this.$router.push({
        path: "/admin-panel/order/" + order.id,
      });
    },
    cancelOrder(order) {
      this.orderToCancel = order;
      this.$refs.cancelOrderConfirmationDialog.openDialog();
    },
    sendReminder(order) {
      this.sendindMail = true;
      const sendMailMissingPaymentFunc = httpsCallable(
        getFunctions(),
        "sendMailMissingPayment"
      );
      sendMailMissingPaymentFunc({
        order: order,
      })
        .then((result) => {
          this.sendindMail = false;
          if (result.data.code == "OK") {
            this.emailSent[order.id] = true;
            this.$snotify.success("Email successfully sent", {
              closeOnClick: false,
              pauseOnHover: false,
              timeout: 1000,
            });
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          this.sendindMail = false;
        });
    },
    onCancelOrder() {
      this.$refs.cancelOrderConfirmationDialog.close();
      this.$store.dispatch("update_order", {
        update_values: {
          general_status: "CANCELED",
          status: "CANCELED",
          update_date: new Date(),
        },
        docId: this.orderToCancel.id,
      });
      this.orderToCancel = null;
    },
    getWinningAmount(item) {
      var total = 0;
      for (var draw_date_key in item.validation) {
        var draw_date = item.validation[draw_date_key];
        if (!draw_date.paid) {
          total += draw_date.total_amount;
        }
      }
      return total;
    },
    getWinningNumbers(item) {
      var numbers = [];
      for (var draw_date_key in item.validation) {
        var draw_date = item.validation[draw_date_key];
        if (!draw_date.paid) {
          for (var numbers_key in draw_date.numbers) {
            numbers.push(
              numbers_key + " : " + draw_date.numbers[numbers_key].toString()
            );
          }
        }
      }
      return numbers;
    },
    closeImage() {
      this.image = undefined;
    },
    creditDialog(item) {
      this.total_amount_dialog = this.getWinningAmount(item);
      this.ticket_to_update = item;
      this.$refs.creditCustomerDialog.openDialog();
    },
    interacDialog(item) {
      this.total_amount_dialog = this.getWinningAmount(item);
      this.ticket_to_update = item;
      this.$refs.transfertInteracDialog.openDialog();
    },
    onCreditConfirmation() {
      this.$refs.creditCustomerDialog.close();
      this.$store
        .dispatch("update_ticket", {
          update_values: { status: "CREDIT" },
          docId: this.ticket_to_update.id,
        })
        .then(() => {
          this.$gtag.event("transfert_winning", {
            type: "CREDIT",
          });
        })
        .catch(() => {});

      this.total_amount_dialog = 0;
      this.ticket_to_update = null;
    },
    onInteracDone() {
      this.$refs.transfertInteracDialog.close();
      this.$store
        .dispatch("update_ticket", {
          update_values: { status: "INTERAC" },
          docId: this.ticket_to_update.id,
        })
        .then(() => {
          this.$gtag.event("transfert_winning", {
            type: "INTERAC",
          });
        })
        .catch(() => {});

      this.total_amount_dialog = 0;
      this.ticket_to_update = null;
    },
    getRowColor(index) {
      if (index % 2 == 0) {
        return "grey lighten-3";
      } else {
        return "grey lighten-2";
      }
    },
    copyToClipboard(text) {
      console.log("Copy to clipboard:", text);
      if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
      ) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
    getDetails(product) {
      var result = [];
      for (var grid of product.ticket.grids) {
        var line = "";
        for (var pool_index in grid.pool) {
          if (pool_index == 0) {
            line = grid.pool[pool_index].numbers.join("-");
          } else {
            line += " (" + grid.pool[pool_index].numbers.join("-") + ")";
          }
        }
        result.push(line);
      }
      return result;
    },
    filter(list, search) {
      var search_text = search == null ? "" : search.trim();
      if (search_text == "") {
        return list;
      }
      var filteredList = list.filter(function (item) {
        var allInfo = (
          item.client_email +
          item.client_firstname +
          item.client_lastname +
          item.client_id
        ).toLowerCase();
        return allInfo.includes(search_text.toLowerCase());
      });
      return filteredList;
    },
    list_count(title) {
      if (title == "Sold") {
        return this.admin_tables.soldList.length.toString();
      } else if (title == "Assigned") {
        return this.admin_tables.assignedList.length.toString();
      } else if (title == "Completed") {
        return this.admin_tables.completedList.length.toString();
      } else if (title == "Canceled") {
        return this.admin_tables.canceledList.length.toString();
      } else if (title == "Winning") {
        return this.admin_tables.winningList.length.toString();
      } else return "-1";
    },
    cancellAllSold() {
      this.$refs.cancelSoldOrdersConfirmationDialog.openDialog();
    },
    onCancelAllSoldOrders() {
      this.$refs.cancelSoldOrdersConfirmationDialog.close();
      var update_date = new Date();
      for (var item of this.admin_tables.soldList) {
        console.log("Deleting order: ", item);
        this.$store.dispatch("update_order", {
          update_values: {
            general_status: "CANCELED",
            status: "CANCELED",
            update_date: update_date,
          },
          docId: item.id,
        });
      }
    },
    titleCase(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
    label_multiplay(multiplay) {
      if (multiplay == 1) {
        return "single draw";
      } else {
        return multiplay.toString() + " draws";
      }
    },

    // type = sold or assigned
    calculate_stats(order_list, type) {
      var result = {};

      for (var item_index in order_list) {
        var item = order_list[item_index];

        for (var ticket_index in item.products) {
          var ticket = item.products[ticket_index].ticket;

          if (result[ticket.type_name] == undefined) {
            result[ticket.type_name] = {
              quickPicks: {
                multiplier: {},
                no_multiplier: {},
              },
              grids: {
                multiplier: {},
                no_multiplier: {},
              },
            };
          }
          var multiplier_label = ticket.multiplier
            ? "multiplier"
            : "no_multiplier";
          if (ticket.quickPicks != undefined) {
            if (
              result[ticket.type_name].quickPicks[multiplier_label][
                ticket.quickPicks.toString() + "-" + ticket.multiplay.toString()
              ] == undefined
            ) {
              result[ticket.type_name].quickPicks[multiplier_label][
                ticket.quickPicks.toString() + "-" + ticket.multiplay.toString()
              ] = 1;
            } else {
              result[ticket.type_name].quickPicks[multiplier_label][
                ticket.quickPicks.toString() + "-" + ticket.multiplay.toString()
              ] += 1;
            }
          } else {
            if (
              result[ticket.type_name].grids[multiplier_label][
                ticket.grids.length.toString() +
                  "-" +
                  ticket.multiplay.toString()
              ] == undefined
            ) {
              result[ticket.type_name].grids[multiplier_label][
                ticket.grids.length.toString() +
                  "-" +
                  ticket.multiplay.toString()
              ] = 1;
            } else {
              result[ticket.type_name].grids[multiplier_label][
                ticket.grids.length.toString() +
                  "-" +
                  ticket.multiplay.toString()
              ] += 1;
            }
          }
        }
      }

      var result_text = "";
      var colors = {
        powerball: "red lighten-4",
        megamillions: "blue lighten-4",
      };
      for (var lottery_type in result) {
        result_text += "<div class='pa-2 " + colors[lottery_type] + "'>";
        for (var multiplier_item in result[lottery_type]["quickPicks"]) {
          for (var n_and_multiplay in result[lottery_type]["quickPicks"][
            multiplier_item
          ]) {
            var n_and_multiplay_splited = n_and_multiplay.split("-");
            var n_numbers = parseInt(n_and_multiplay_splited[0]);
            var multiplay = parseInt(n_and_multiplay_splited[1]);

            result_text +=
              this.multiplier_label[lottery_type].type +
              " " +
              this.multiplier_label[lottery_type][multiplier_item] +
              n_numbers +
              " Quickpicks (" +
              this.label_multiplay(multiplay) +
              "): <b>" +
              result[lottery_type]["quickPicks"][multiplier_item][
                n_and_multiplay
              ] +
              "</b><br>";
          }
        }
        for (var multiplier_item_grids in result[lottery_type]["grids"]) {
          for (var n_and_multiplay_grids in result[lottery_type]["grids"][
            multiplier_item_grids
          ]) {
            var n_and_multiplay_splited_grids =
              n_and_multiplay_grids.split("-");
            var n_numbers_grids = parseInt(n_and_multiplay_splited_grids[0]);
            var multiplay_grids = parseInt(n_and_multiplay_splited_grids[1]);
            result_text +=
              this.multiplier_label[lottery_type].type +
              " " +
              this.multiplier_label[lottery_type][multiplier_item_grids] +
              n_numbers_grids +
              " Grids (" +
              this.label_multiplay(multiplay_grids) +
              "): <b>" +
              result[lottery_type]["grids"][multiplier_item_grids][
                n_and_multiplay_grids
              ] +
              "</b><br>";
          }
        }
        result_text += "</div>";
      }

      this.calculation_result[type] = result_text;
    },
    scan_batch() {
      this.$router.push({
        //path: "admin-panel/camera/",
        name: "ScanBatch",
        // params: { order_list: this.admin_tables.assignedList },
      });
    },
    scan_with_scanner() {
      this.$router.push({
        //path: "admin-panel/camera/",
        name: "ScanWithScanner",
        // params: { order_list: this.admin_tables.assignedList },
      });
    },
  },
};
</script>
